import React, { ReactDOM,useState,Component } from 'react';
import LOGO from '../static/VL_logo_solid.png'
import axios from 'axios';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBCheckbox
}
from 'mdb-react-ui-kit';  

const account_system_change_password_api_url = process.env.REACT_APP_ACCOUNT_CHANGE_PASSWORD_API_URL;

async function makeRequest(config) {
    let response = await axios.request(config);
    return response.data
};

function deleteCookie(ckey) {
  document.cookie = ckey + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }

  return "";
}

class ChangePassword extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        password: '',
        password_change: '',
        password_change_again: ''
      };
    }
    
    handleSubmit = (event) => {
      var data = JSON.stringify({
        "current_password": this.state.password.toString(),
        "new_password": this.state.password_change.toString()
      });
      const token_login = getCookieValue('token')
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: account_system_change_password_api_url,
        headers: { 
          'content_type': 'string',
          'Authorization': 'Brearer'+token_login, 
          'Content-Type': 'application/json'
        },
        data : data
      };
        event.preventDefault();
         this.setState({ errorMessage: ''});
         this.setState({ PasswordAgainMessage: '' });
        if (this.state.password_change === this.state.password_change_again) {
          makeRequest(config)
            .then((result) => {
              if (result['status_code']===200) {
                this.setState({ message: "Thay đổi mật khẩu thành công vui lòng đăng nhập lại!" });
                deleteCookie('token')
                deleteCookie('pwd')
                window.location.href= '/login';
              } else {
                switch(result['error_code']){
                  case 401:
                    alert('Đã hết phiên đăng nhập vui lòng đăng nhập lại!')
                    deleteCookie('token')
                    window.location.href= '/login';
                  case 504:
                    this.setState({errorMessage: result['message'].toString()});
                  case 505:
                    this.setState({errorMessage: "Mật khẩu không chính xác vui lòng thử lại"});
                }
              }
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
            this.setState({ PasswordAgainMessage: "Mật khẩu không khớp" });
        }
        };
      render(){  
        return (
          <MDBContainer fluid>
      
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
              <MDBCol col='12'>
              
                <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
                  <MDBCardBody className='p-5 w-100 d-flex flex-column'>
                  <MDBCardImage src={LOGO} alt="login form" className='rounded-start w-5' style={{margin:'auto', maxWidth: '100px'}}/>
                    <h2 className="fw-bold mb-2 text-center">Đổi mật khẩu</h2>
                    {/* <p className="text-black-50 mb-3">Nhập mất khẩu hiện tại và mật khẩu thay đổi!</p> */}
                    {this.state.message && <p className="text-success">{this.state.message}</p>}
                    {this.state.errorMessage && <p className="text-danger">{this.state.errorMessage}</p>}
                    <MDBInput wrapperClass='mb-4 w-100' label='Mât khẩu hiện tại' id='formControlLg' type='password' size="lg" name="password" value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}/>
                    <MDBInput wrapperClass='mb-4 w-100' label='Mật khẩu mới' id='formControlLg' type='password' size="lg" name="password_change" value={this.state.password_change} onChange={(event) => this.setState({password_change: event.target.value})}/>
                    <MDBInput wrapperClass='mb-4 w-100' label='Xác nhận mật khẩu' id='formControlLg' type='password' size="lg" name="password_change_again" value={this.state.password_change_again} onChange={(event) => this.setState({password_change_again: event.target.value})}/>
                    {this.state.PasswordAgainMessage && <p className="text-danger">{this.state.PasswordAgainMessage}</p>}
                    <MDBBtn size='lg' onClick={this.handleSubmit}>
                      Xác nhận
                    </MDBBtn>
                    <a className='text-center' href="/">Back to Home</a>
                    <hr className="my-4" />
      
                  </MDBCardBody>
                </MDBCard>
      
              </MDBCol>
            </MDBRow>
      
          </MDBContainer>
        );}
}

export default ChangePassword;