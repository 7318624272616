import React, { useState, Component } from 'react';
import LOGO from './static/VL_logo_solid.png'
import axios from 'axios';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
}
from 'mdb-react-ui-kit';  
async function makeRequest(config) {
    let response = await axios.request(config);
    return response.data
};
const account_system_login_api_url = process.env.REACT_APP_ACCOUNT_SYSTEM_LOGIN_API_URL;
const pssw_default = process.env.PASSWORD_DEFAULT;
const change_password_url = process.env.REACT_APP_CHANGE_PASSWORD_URL;

function setCookie(ckey, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  let cookieValue = encodeURIComponent(ckey) + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/;";
  document.cookie =  cookieValue
}

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }

  return "";
}

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emp_id: '',
      password: '',
    };
  }

  handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();
    var data = JSON.stringify({
      "user_name": this.state.emp_id.toString(),
      "password": this.state.password.toString()
    });
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: account_system_login_api_url,
      headers: {
        'content_type': 'string',
        'Content-Type': 'application/json'
      },
      data: data
    };
    makeRequest(config)
      .then((result) => {
        if (result['status_code'] === 200) {
          this.setState({ message: "You're logged in" });
          setCookie('token', result['data']['token'], 365);
          setCookie('pwd', this.state.password.toString(), 365);
          window.location.href = '/'
        } else {
          this.setState({ errorMessage: result['message'].toString() });
        }
      })
      .catch((error) => {
        console.log(error)
      })
          handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.handleSubmit()
      }
    };
  };
  render() {
    return (
      <MDBContainer className="my-5">

        <MDBCard>
          <MDBRow className='g-0'>

            <MDBCol md='6'>
              <MDBCardImage src={LOGO} alt="login form" className='rounded-start w-100' />
            </MDBCol>

            <MDBCol md='6'>
              <MDBCardBody className='d-flex flex-column'>

                <div className='d-flex flex-row mt-2'>
                  <MDBIcon fas icon="truck fa-3x me-3" style={{ color: '#ff6219' }} />
                  <span className="h1 fw-bold mb-0">Vạn Lợi</span>
                </div>

                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Đăng nhập tài khoản</h5>
                {this.state.message && <p className="text-success">{this.state.message}</p>}
                {this.state.errorMessage && <p className="text-danger">{this.state.errorMessage}</p>}
                <MDBInput wrapperClass='mb-4' label='Mã nhân viên' id='formControlLg' type='email' size="lg" name="emp_id" value={this.state.emp_id} onChange={(event) => this.setState({ emp_id: event.target.value })} />
                <MDBInput wrapperClass='mb-4' label='Mật khẩu' id='formControlLg' type='password' size="lg" name="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />

                <MDBBtn className="mb-4 px-5" color='dark' size='lg' onClick={this.handleSubmit}>Đăng nhập</MDBBtn>
                {/* <a className="small text-muted" href="#!">Quên mật khẩu?</a>
                <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>Bạn không có tài khoản ? <a href="#!" style={{ color: '#393f81' }}>Đăng ký</a></p> */}

                {/* <div className='d-flex flex-row justify-content-start'>
                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                    <a href="#!" className="small text-muted">Privacy policy</a>
                  </div> */}

              </MDBCardBody>
            </MDBCol>

          </MDBRow>
        </MDBCard>

      </MDBContainer>
    );
  }
}

export default Login;