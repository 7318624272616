import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import Login from './App';
import ChangePassword from './pages/ChangePassword';
import Basic from './pages/Basic';
import reportWebVitals from './reportWebVitals';

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }

  return "";
}
const pwd_deafault = process.env.REACT_APP_PASSWORD_DEFAULT;
const change_password_url = process.env.REACT_APP_CHANGE_PASSWORD_URL;
const pwd = getCookieValue('pwd')
const token = getCookieValue('token');
export default function App() {
  document.title = "Vạn lợi"
  if (window.location.pathname !== ('/login') && !token) {
    console.log(window.location.pathname)
    window.location.href = '/login';
  }
  if (window.location.pathname !== change_password_url && pwd == pwd_deafault) {
    window.location.href = change_password_url;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/" element={<Basic />} />
        <Route path="/home" element={<Basic />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
