import React, { useState } from 'react';
import HR from '../static/VL_logo_solid.png'
import FPL from '../static/logo-FPL-02-148x104.png'
import Image1 from '../static/Picture1.png'
import Image2 from '../static/Picture2.png'
import Image3 from '../static/Picture3.png'
import Image4 from '../static/Picture4.png'
import './basic.css';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBCardBody,
  MDBIcon,
  MDBCheckbox,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBRipple,
  MDBBadge,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }

  return "";
}

function deleteCookie(ckey) {
  document.cookie = ckey + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
function Basic() {
  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);
  const logout = () => {
    deleteCookie('token');
    deleteCookie('pwd');
    window.location.href = '/login';
  };
  const token_login = getCookieValue('token')
  
  const fp_v14_login_url = process.env.REACT_APP_FP_V14_LOGIN_URL + token_login ;
  const vl_v16_login_url = process.env.REACT_APP_VL_V16_LOGIN_URL + token_login;
  const change_password_url = process.env.REACT_APP_CHANGE_PASSWORD_URL;
  return (
    <>
      <div className='bg-secondary bg-gradient'>
        <link
          href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
          rel="stylesheet"
        />
        <MDBCollapse show={showShow} tag="nav" className="d-lg-block bg-white sidebar">
          <div className="position-sticky">
            <MDBListGroup flush="true" className="mx-3 mt-4">

              {/* <MDBRipple rippleTag='span'>
                <MDBListGroupItem tag='a' href='https://hr.vanloi-log.vn/web/login?from_authe=1' 
                action className='border-0 border-bottom rounded rounded' target="blank">
                  <MDBIcon fas icon="user-alt me-3" />
                  Nhân sự
                </MDBListGroupItem>
              </MDBRipple> */}

              <MDBRipple rippleTag='span'>
                <MDBListGroupItem tag='a' href={fp_v14_login_url} action 
                className='border-0 border-bottom rounded rounded' target="blank">
                  <MDBIcon fas icon="boxes me-3" />
                  FPL
                </MDBListGroupItem>
              </MDBRipple>

              <MDBRipple rippleTag='span'>
                <MDBListGroupItem tag='a' href={vl_v16_login_url} action 
                className='border-0 border-bottom rounded rounded' target="blank">
                  <MDBIcon fas icon="truck me-3" />
                  Vạn Lợi
                </MDBListGroupItem>
              </MDBRipple>


              <MDBRipple rippleTag='span'>
                <MDBListGroupItem tag='a' href={change_password_url} action className='border-0 border-bottom rounded' >
                  <MDBIcon fas icon="chart-area me-3" />
                  Change Password
                </MDBListGroupItem>
              </MDBRipple>

            </MDBListGroup>
          </div>
        </MDBCollapse>

        <MDBNavbar expand='lg' light bgColor='light'>
          <MDBContainer fluid>
            <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
              <MDBNavbarToggler
                type='button'
                aria-label='Toggle navigation'
                onClick={toggleShow}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>
              <MDBNavbarBrand href='/home'>
                <img
                  src={HR}
                  height='60'
                  width='70'
                  alt=''
                  loading='lazy'
                />
              </MDBNavbarBrand>



            </MDBNavbarNav>
            <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">

              <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
                <MDBDropdown>

                  <MDBDropdownToggle tag="a" href="#!" className="hidden-arrow nav-link">
                    <MDBIcon fas icon="bell" />
                  </MDBDropdownToggle>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
                <MDBDropdown>

                  <MDBDropdownToggle tag="a" href="#!" className="hidden-arrow nav-link">
                    <img src={HR} className="rounded-circle border border-dark" height="26" alt="" loading="lazy" />
                  </MDBDropdownToggle>

                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBDropdownItem link="#">My profile</MDBDropdownItem>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownItem link="#">Settings</MDBDropdownItem>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownItem onClick={logout} link="#">Logout</MDBDropdownItem>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            </MDBNavbarNav>

          </MDBContainer>
        </MDBNavbar>
        <MDBContainer fluid>
          <div className="row gy-5 bg-secondary bg-gradient">
            <div className="col-2">
            </div>
            <div className="col-10">
              <div className="row p-5">
                <div className='col-7'>
                  <h1 className='text-black text-center' position='sticky'>CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ PHÁT TRIỂN KHO VẬN VẠN LỢI</h1>
                  <br />

                  <MDBCardImage className='image1' src={Image1} alt='...' position='right' />

                </div>
                <div className="col-5">
                  {/* <div className='d-flex justify-content-center'>
                    <a className='link-dark' href='https://hr.vanloi-log.vn/web/login?from_authe=1' target='_blank'>
                      <MDBCard style={{ maxWidth: '840px' }} className='shadow p-3 mb-5 rounded-5'>
                        <MDBRow className='g-0'>
                          <MDBCol md='3'>
                            <MDBCardImage className='my-3' src={Image4} alt='...' fluid />
                          </MDBCol>
                          <MDBCol md='8'>
                            <MDBCardBody>
                              <MDBCardTitle>Nhân sự</MDBCardTitle>
                              <MDBCardText>
                                Hệ thống nhân sự là hệ thống quản lý tài nguyên con người dành cho nhân viên nhân sự
                              </MDBCardText>
                              <MDBCardText>
                                <small className='text-muted'></small>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </a>
                  </div>
                  <br /> */}
                  <div className='d-flex justify-content-center'>
                    <a className='link-dark' href={fp_v14_login_url} target='_blank'>
                      <MDBCard style={{ maxWidth: '840px' }} className='shadow p-3 mb-5 rounded-5'>
                        <MDBRow className='g-0'>
                          <MDBCol md='3'>
                            <MDBCardImage src={Image3} alt='...' fluid />
                          </MDBCol>
                          <MDBCol md='8'>
                            <MDBCardBody>
                              <MDBCardTitle>FP Logistics</MDBCardTitle>
                              <MDBCardText>
                                Hệ thống FP Logistics cung cấp dịch vụ vận tải hàng ghép và hàng nguyên chuyến dành cho nhân viên FPL
                              </MDBCardText>
                              <MDBCardText>
                                <small className='text-muted'></small>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </a>
                  </div>
                  <br />
                  <div className='d-flex justify-content-center'>
                    <a className='link-dark' href={vl_v16_login_url} target='_blank'>
                      <MDBCard style={{ maxWidth: '840px' }} className='shadow p-3 mb-5 rounded-5'>
                        <MDBRow className='g-0'>
                          <MDBCol md='3'>
                            <MDBCardImage className='my-3' src={Image2} alt='...' fluid />
                          </MDBCol>
                          <MDBCol md='8'>
                            <MDBCardBody>
                              <MDBCardTitle>Vạn Lợi</MDBCardTitle>
                              <MDBCardText>
                                Hệ thống Vạn Lợi nhằm để vận hàng nguyên chuyến và chi phi dành cho nhân viên vận hành và đối soát của vận hành
                              </MDBCardText>
                              <MDBCardText>
                                <small className='text-muted'></small>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCol>
                        </MDBRow>
                      </MDBCard>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1">
            </div>
          </div>

        </MDBContainer>
      </div>
    </>
  );
}
export default Basic